import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FastClick from 'fastclick'
import VueClipBoard from 'vue-clipboard2'
// 解决移动端点击延迟200ms的问题
if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function () {
        FastClick.attach(document.body);
    }, false);
}
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 全局引入 Reset-css
import 'reset-css'
import './assets/css/global.css'
import './assets/css/common.less'
router.beforeEach((to,from,next)=>{
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    next();
})
const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(VueClipBoard)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
