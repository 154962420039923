<template>
    <router-view></router-view>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
          
      }
    },
    watch: {
    
    },
    created() {
    
    },
    mounted() {
      
    },
    methods: {
      

    }
  }
</script>

<style lang="less">
  
</style>
