import { createStore } from 'vuex'
export default createStore({
    state:{
        
    },
    mutations:{
        
    },
    actions:{

    },
    modules:{
        
    }
})